<template>
  <section class="pqr-ver-solicitud">
      <div class="row h-100">
          <div class="col-4">
              <div class="container-section overflow-auto h-100 ml-2 border-right">
                  <div class="row mx-0 mt-4">
                      <div class="col-12">
                          <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                              <el-breadcrumb-item :to="{ name: 'pqr.solicitudes' }">PQRs</el-breadcrumb-item>
                              <el-breadcrumb-item>{{ pqr.id }} - {{ pqr.titulo }}</el-breadcrumb-item>
                          </el-breadcrumb>
                      </div>
                  </div>
                  <div class="row mx-0 mb-3 mt-4">
                      <p class="col-12 f-20 f-500">{{ pqr.id }} - {{ pqr.titulo }}</p>
                  </div>
                  <div class="row mx-0 mb-3">
                      <div class="col-auto">
                          <img v-show="pqr.animo == 1" src="/img/pqr/enojadoAmarillo.svg" alt="">
                          <img v-show="pqr.animo == 2" src="/img/pqr/tristeAmarillo.svg" alt="">
                          <img v-show="pqr.animo == 3" src="/img/pqr/confuso-1.svg" alt="">
                          <img v-show="pqr.animo == 4" src="/img/pqr/felizAmarillo.svg" alt="">
                          <img v-show="pqr.animo == 5" src="/img/pqr/enamorado.svg" alt="">
                      </div>
                      <div class="col-auto my-auto">
                          <span class="bg-db rounded-pill px-3 py-1 f-12 text-center">
                              <span v-show="pqr.tipo == 1">Petición</span>
                              <span v-show="pqr.tipo == 2">Queja</span>
                              <span v-show="pqr.tipo == 3">Reclamo</span>
                              <span v-show="pqr.tipo == 4">Sugerencia</span>
                          </span>
                      </div>
                      <div class="col-auto my-auto">
                          <span :class="`rounded-pill pl-1 pr-3 py-1 f-12 text-center ${colorEstado}`">
                              <i :class="`f-13 op-05 ${iconoEstado}`" />
                              <span v-if="pqr.estado === 1" class="op-05">Abierta</span>
                              <span v-else-if="pqr.estado == 0" class="op-05">Pendiente</span>
                              <span v-else class="op-05">Resuelta</span>
                          </span> 
                      </div>
                  </div>
                  <div class="row mx-0 mb-3">
                      <div class="col-auto">
                          <div class="d-flex tres-puntos">
                              <div class="wh-32px rounded-circle d-middle-center bg-azulclaro">
                                  <i class="icon-account-outline f-20" />
                              </div>
                              <div class="ml-3">
                                  <p class="f-15 tres-puntos f-500"> {{ pqr.usuario.nombre || 'No registra'}} </p>
                                  <p class="f-12 text-86 tres-puntos"> {{ pqr.usuario.vivienda || 'No registra'}} </p>
                                  <p class="f-12 text-86"><i class="icon-phone mr-1"/>{{ pqr.usuario.telefono || 'No registra'}}</p>
                                  <p class="f-14 mt-2">Solicitado: {{ formatearFecha(pqr.created_at)}} </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div v-if="$usuario.tipo != 4" class="row mx-0 mb-3">
                       <el-checkbox v-model="pqr.vigilancia" :true-label="1" :false-label="0" class="mx-3 f-15 f-500 my-1" @change="habilitarVigilante" >Lo puede ver vigilante</el-checkbox>
                  </div>
                  <div class="row mx-0 mb-3">
                      <p class="col-12 f-15 f-500">Descripción</p>
                      <p class="col-12 f-15"> {{ pqr.descripcion }} </p>
                  </div>
                  <div v-if="pqr.imagenes.length" class="row mx-0 mb-3">
                      <p class="col-12 f-15 f-500 mb-3">Adjuntos</p>
                      <div v-for="(img,index) in pqr.imagenes" :key="index" class="col-auto pr-1 mb-3 cr-pointer" @click="verAdjuntos(index)">
                          <div class="contenedor-img">
                              <img :src="img.ruta" alt="" class="w-100 h-100 br-2">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-4">
              <div class="container-section overflow-auto scroll-old" style="height: calc(100vh - 65px)">
                  <div class="row mx-0 mt-4">
                      <p v-show="coment.length || [0,1].includes(pqr.estado)" class="col-12 f-20 f-500 mb-4">Comentarios</p>
                      <p v-show="!coment.length" class="col-12">No hay comentarios en esta Pqr</p>

                      <div class="col-12">
                          <div class="listado-comentarios">
                              <div v-for="(com,index) in coment" :key="index"  class="comentario br-4 p-2 mb-3 row-4">
                                  <p v-if="com.tipo == 1" class="f-15 lh-18" v-html="formatearTextoHtml(com.comentario)"></p>
                                  <img v-else :src="com.comentario" @click="handlePreview(com.comentario)" class="cr-pointer br-4"/>
                                  <p class="text-86 mt-2 f-14"> {{ formatearFecha(com.created_at,'DD MMM YYYY h:m a') }} </p>
                                   <el-tooltip placement="left" effect="light" :visible-arrow="false" popper-class="br-20 py-1 border-0 text-5d shadow-sm">
                                      <div slot="content">
                                          <p class="f-12">Comentario enviado a todos los PQRs vinculados</p>
                                      </div>
                                      <i v-show="com.vinculado" class="icon-unir" />
                                  </el-tooltip>
                              </div>
                              <div v-if="pqr.estado != 2" class="agregar-comentario rounded-circle bg-general d-middle-center cr-pointer" @click="agregarComentario">
                                  <i class="icon-message-reply-outline text-white f-25" />
                              </div>
                          </div>
                      </div>
                      <!-- <div class="col-12">
                          <infinite-loading @infinite="getComentariosPqr">
                              <div slot="spinner">Consultando...</div>
                              <div slot="no-more"></div>
                              <div slot="no-results"></div>
                          </infinite-loading>
                      </div> -->
                  </div>
              </div>
          </div>
          <div class="col-4">
              <div class="container-section overflow-auto scroll-old h-100">
                  <div class="section-vicular">
                      <div class="row mx-0">
                          <div class="col-12">
                              <el-collapse v-model="activeName" accordion>
                                  <el-collapse-item name="1">
                                      <template slot="title">
                                          <div class="row justify-content-around my-4">
                                              <p class="col-auto f-20 f-500">PQRs vinculadas ({{ vinculadas.length }})</p>
                                              <div v-if="!pqr.id_grupo" class="col-auto ml-auto" @click="realizarVinculacion">
                                                  <button class="btn h-32px btn-general f-300 f-14 px-3" >Vincular</button>
                                              </div>
                                          </div>
                                      </template>
                                      <div v-if="pqr.id_grupo">
                                          <div v-for="(vin,index) in vinculadas" :key="index" class="row mb-2">
                                              <div class="col-6 mb-1">
                                                  <p class="f-14 f-500">{{ vin.id }} - {{ vin.titulo }}</p>
                                              </div>
                                              <div class="col-6 text-86">
                                                  <p>{{ formatearFecha(vin.created_at) }}</p>
                                              </div>
                                          </div>
                                      </div>
                                      <p v-show="!vinculadas.length && pqr.id_grupo" class="col-12">No hay Pqr vinculadas a esta resolución</p>
                                  </el-collapse-item>
                              </el-collapse>
                          </div>
                      </div>
                      <div class="row mx-0 mt-3">
                          <p class="col-12 f-20 f-500">{{ resolucion.titulo }}</p>
                          <p v-show="!comentariosResolucion.length && pqr.id_grupo" class="col-12">No hay comentarios en esta resolución</p>

                      </div>
                      <div v-for="(com,index) in comentariosResolucion" :key="index" class="row mx-0 my-3">
                          <div class="col-12">
                              <div v-if="com.tipo==1" class="set-comentarios br-4 p-2">
                                  <p  class="f-15 lh-18" v-html="formatearTextoHtml(com.comentario)"></p>
                                  <p class="text-right f-12 mt-1 text-muted">{{ formatearFecha(com.created_at,'DD MMM YYYY h:m a')}}</p>
                              </div>
                          </div>
                          <div v-if="com.tipo==2" class="set-comentarios br-4 p-2 cr-pointer">
                                  <img :src="com.comentario" width="100px" height="100px" class="br-4" @click="handlePreview(com.comentario)">
                              </div>
                        </div>
                      <div v-if="pqr.id_grupo" class="col-12">
                          <infinite-loading @infinite="getComentariosResolucion">
                              <div slot="spinner">Consultando...</div>
                              <div slot="no-more"></div>
                              <div slot="no-results"></div>
                          </infinite-loading>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- Partials -->
      <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      <modal-ver-img ref="abrirModalVerImagenes" />
      <modal-lateral ref="abrirModalAgregarComentario" titulo="Añadir un comentario">
          <ValidationObserver tag="div" class="row mx-0" ref="form">
                  <div class="col-12">
                      <ValidationProvider v-slot="{ errors }" rules="required|max:1000" name="comentario">
                          <el-input placeholder="Responde al residente o a los residentes" v-model="inputComentario" show-word-limit maxlength="1000" type="textarea"  :autosize="{ minRows: 5}" />
                          <p class="text-danger f-10">{{ errors[0] }}</p>
                      </ValidationProvider>
                  </div>
                  <div class="col-12 mt-3">
                      <label class="d-middle mr-3 f-15" for="">
                          <input v-model="tipoEnvio" :value="1" type="radio" class="option-input opt-gris radio mr-2" /> Enviar solo a este PQR
                      </label>
                  </div>
                  <div :class="`col-12 mt-3 ${pqr.id_grupo ? '' : 'op-04'}`">
                      <label class="d-middle mr-3 f-15" for="">
                          <input v-model="tipoEnvio" :value="2" :disabled="!pqr.id_grupo" type="radio" class="option-input opt-gris radio mr-2" /> Enviar a todos los PQRs vinculados
                      </label>
                  </div>
                  <div class="col-12 mt-4 el-checkbox-negro">
                        <el-checkbox v-model="checkEnviarResolver">Enviar comentario y resolver PQR</el-checkbox>
                  </div>
                  <div class="botones">
                       <button type="button" class="btn mx-2 btn-cerrar f-300 f-14 h-32px px-4 br-4 border bg-db text-86" @click="cerrarComentario"> Cerrar </button>
                       <button type="button" class="btn mx-2 btn-aceptar f-300 f-14 h-32px text-white br-4 bg-db" @click="crearComentario"> Enviar </button>
                  </div>
          </ValidationObserver>
      </modal-lateral>
      <modal-confirmar ref="abrirModalRealizarVinculacion" titulo="Vincular a una resolución" adicional="Vincular" @adicional="vincularPqr">
          <div class="row justify-content-center py-3">
              <div class="col-10 mb-3">
                  <p class="f-15">Selecciona la resolución que vinculará a esta PQR.</p>
              </div>
              <div class="col-10">
                  <p class="f-12 pl-2 label-inputs">Seleccionar resolución</p>
                  <el-select v-model="idGrupo" placeholder="Buscar" filterable remote :remote-method="buscarResoluciones" size="small" class="w-100">
                      <el-option v-for="(res,index) in listadoResoluciones" :key="index" :label="res.titulo" :value="res.id" />
                  </el-select>
              </div>
          </div>
      </modal-confirmar>
  </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';

import PQRS from '~/services/pqrs'
export default {
  components:{
      modalVerImg: () => import ('./partials/modalVerImg'),
      InfiniteLoading
  },
  data(){
      return{
          page: 1,
          pageGrupo: 1,
          pqr: {
              id: null,
              usuario:{
                  nombre: '',
                  vivienda: '',
                  telefono: null
              },
              id_grupo: null,
              imagenes: []
          },
          resolucion: {},
          comentariosResolucion: [],
          vinculadas: [],
          dialogImageUrl: '',
          dialogVisible: false,
          comentarios: [],
          coment: [],
          inputComentario:'',
          tipoEnvio: 1,
          checkEnviarResolver: false,
          listadoResoluciones: [],
          idGrupo: null,
          activeName: '1',
      }
  },
  computed: {
      colorEstado(){
          switch (this.pqr.estado) {
              case 0:
                  return 'bg-rojo-claro'
              case 1:
                  return 'bg-azul-claro'
              default:
                  return 'bg-activo'
          }
      },
      iconoEstado(){
          switch (this.pqr.estado) {
              case 0:
                  return 'icon-clock-progress'
              case 1:
                  return 'icon-clock-outline'
              default:
                  return 'icon-confirmado'
          }
      }
  },
  async created(){
      await this.getPqrs()
  },
  mounted(){
    this.getComentariosPqr()
  },
  methods:{
      handlePreview(file){
          this.dialogImageUrl = file
          this.dialogVisible = true
      },
      verAdjuntos(index){
          this.$refs.abrirModalVerImagenes.toggle(index,this.pqr.imagenes)
      },
      agregarComentario(){
          this.inputComentario = ''
          this.$refs.abrirModalAgregarComentario.toggle()
      },
      cerrarComentario(){
          this.$refs.abrirModalAgregarComentario.toggle()
      },
      enviarComentario(){
          this.$refs.abrirModalAgregarComentario.toggle()
      },
      realizarVinculacion(){
          this.idGrupo = null
          this.$refs.abrirModalRealizarVinculacion.toggle()
      },
      async getPqrs(){
          try {
              const { data } = await PQRS.getPqrsPorId(this.$route.params.id_pqr)
              this.pqr = data.data
              
              if(this.pqr.id_grupo){
                  this.getPqrsVinculadas()
                  this.getResolucion()
              }

          } catch (error) {
              this.errorCatch(error)
              this.$router.replace({name: "pqr.solicitudes"})
          }
      },
      async getPqrsVinculadas(){
          try {
              const { data } = await PQRS.getPqrsVinculadas(this.pqr.id)
              this.vinculadas = data.data
          } catch (error) {
              this.errorCatch(error)
          }
      },
      async buscarResoluciones(search){
          try {
              let params = {
                  estado: 1,
                  search: search
              }
              const { data } = await PQRS.buscarResoluciones(params)
              this.listadoResoluciones = data.data.data

          } catch (error) {
              this.errorCatch(error)
          }
      },
      async getResolucion(){
          try {
              const { data } = await PQRS.getResolucionXId(this.pqr.id_grupo)
              this.resolucion = data.data
          } catch (error) {
              this.errorCatch(error)
          }
      },
      async getComentariosResolucion($state){
          try {

              const state = $state
              // if(!this.pqr.id_grupo) $state.loaded()
              let page = this.pageGrupo
              const { data } = await PQRS.getComentariosGrupo(this.pqr.id_grupo,{page})
              if(data.data.data.length){
                  this.pageGrupo += 1;
                  this.comentariosResolucion.push(...data.data.data)
                  if(typeof state !== 'undefined'){
                      state.loaded();
                  }
                  }else{
                  if(typeof state !== 'undefined'){
                      state.complete();
                  }
              }
          } catch (error) {
              this.errorCatch(error)
          }
      },
      async getComentariosPqr(){
          try {
              const { data } = await PQRS.getComentariosPqr(this.$route.params.id_pqr)
              this.coment = data.comentarios

          } catch (error) {
              this.errorCatch(error)      
          }
      },
      async crearComentario(){
          try {
              let validate = await this.$refs.form.validate()
              if(!validate) return this.notificacion('','Por favor escriba un comentario')
              // payload según caso
              let params =  {
                  'id_pqrs': this.pqr.id,
                  'comentario': this.inputComentario,
                  'vinculadas': true
              }

              if(this.tipoEnvio == 1) delete params.vinculadas

              const { data } = await PQRS.crearComentarioPqr(params);

              if(this.checkEnviarResolver){
                  const { dataF } = await PQRS.finalizarPqr({id_pqrs: params.id_pqrs})
              } 

              this.notificacion('',`Comentario creado ${this.checkEnviarResolver ? 'y pqr resuelta ' : '' }correctamente`,'success')

              await this.getPqrs()
              this.page = 1
              this.comentarios = []
              this.getComentariosPqr()
              this.cerrarComentario()

          } catch (error) {
              this.errorCatch(error)
          }
      },
      async vincularPqr(){
          try {

              if(this.idGrupo == null) return this.notificacion('','Por favor escoge una resolución')

              let params = {
                  id_pqrs: this.pqr.id,
                  id_grupo: this.idGrupo
              }
              const { data } = await PQRS.vincularPqr(params)

              this.notificacion('','Pqr vinculada correctamente','success')
              this.getPqrs()
              
          } catch (error) {
              this.errorCatch(error)
          }
      },
      async habilitarVigilante(){
          try {
              await PQRS.habilitarVigilante({idPqr: this.pqr.id, vigilante: this.pqr.vigilancia})
              this.notificacion('','Actualizado correctamente','success')
              this.getPqrs()

          } catch (error) {
              this.errorCatch(error)
          }
      }
  }
}
</script>
<style lang="scss" scoped>
.pqr-ver-solicitud{
  height: calc(100vh - 65px);
  .contenedor-img{
      width: 112px;
      height: 74px;
  }
  .set-comentarios{
      background: var(--color-f5);
      border: 1px solid var(--color-db);
  }
  .section-vicular{
      height: calc(100vh - 85px);
  }
  .listado-comentarios{
      height: calc(100vh - 140px);
      position: relative;
      .comentario{
          @extend .set-comentarios;
          position: relative;
          .icon-unir{
              position:absolute;
              bottom: 4px;
              right: 4px;
          }
      }
      .agregar-comentario{
          position: sticky;
          min-width: 51px;
          max-width: 51px;
          min-height: 51px;
          max-height: 51px;
          bottom: 14px;
          margin-left: auto;
      }
  }
}
</style>